<template>
    <footer>
        <div v-if="related.length" class="lg:hidden overflow-x-auto w-full md:max-w-2xl mainpagecontent mx-auto p-4">
            <DocsRelated :related="related" />
        </div>

        <div class="flex justify-center gap-8 flex-wrap items-center">
            <Author :author="page?.author" v-if="page?.author" />
            <Author :author="page?.author?.coauthors" v-if="page?.author && page?.author.coauthors" />
        </div>

        <div class="max-w-2xl mx-auto keywords flex justify-center flex-wrap gap-2 pb-8" v-if="page?.keywords">
            <span v-for="keyword in page.keywords ">{{ keyword }}</span>
        </div>

        <div v-if="articleType === 'template'">
            <span class="flex items-center justify-center gap-2 block text-sm">
                <Icon name="heroicons:calendar-solid" class="mb-[1px]" />
                {{ articleDate }}
            </span>
        </div>

        <ArticleShare :href="currentUrl" v-if="article" />

        <BannerNewsletter v-if="article" />

        <DocsPrevNext v-if="!isSingle" />

        <div class="mt-14" v-if="(article && hasComments !== false) || (!article && hasComments)">
            <ArticleComments :title="page.title" />
        </div>
    </footer>
</template>

<script setup>
const props = defineProps({
    article: Object,
    related: Array,
    page: Object,
    articleType: String,
    isSingle: Boolean,
    currentUrl: String,
    hasComments: Boolean
})

const articleDate = new Date(props.article?.date)?.toLocaleString('en-EN', {
    month: 'long', day: 'numeric',
    year: 'numeric'
}) ?? ''
</script>

<style scoped>
.keywords span {
    color: #a1a1a1;

    &::before {
        content: "#";
        color: #cdcdcd;
    }
}
</style>
